<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="list" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.activitycommon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="btime" title="开始时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="etime" title="结束时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{row.status==1?'有效':'无效'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activitycommon/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="openlog(row)">详情</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<el-dialog title="编辑/新增" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="月度会员" prop="isvip">
					可兑换 <el-input-number type="number" :min="0" :max="30" :step="1" :precision="0" v-model="editDialogFormData.cfg.d30" style="width: 108px;" class="input" size="small" placeholder="次数">
						</el-input-number> 次
				</el-form-item>
				<el-form-item label="季度会员" prop="isvip">
					可兑换 <el-input-number type="number" :min="0" :max="30" :step="1" :precision="0" v-model="editDialogFormData.cfg.d90" style="width: 108px;" class="input" size="small" placeholder="次数">
						</el-input-number> 次
				</el-form-item>
				<el-form-item label="半年卡" prop="isvip">
					可兑换 <el-input-number type="number" :min="0" :max="30" :step="1" :precision="0" v-model="editDialogFormData.cfg.d180" style="width: 108px;" class="input" size="small" placeholder="次数">
						</el-input-number> 次
				</el-form-item>
				<el-form-item label="年卡" prop="isvip">
					可兑换 <el-input-number type="number" :min="0" :max="30" :step="1" :precision="0" v-model="editDialogFormData.cfg.d360" style="width: 108px;" class="input" size="small" placeholder="次数">
						</el-input-number> 次
				</el-form-item>
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'

	export default {
		name: 'vip-box-lunch',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '会员权益1',
				isLoading: false,
				searchFormData:{},
				list:[],
				totalPage:0,
				rules: {
				},
				editDialogShow:false,
				editDialogFormData: {cfg:{d30:0,d90:0,d180:0,d360:0}},
			}
		},
		computed: {
		},
		mounted() {
			this.getList();
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					tp: 4,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.list(params);
				this.list = res.data;
				this.totalPage = res.total;
				if(res.data.length){
					this.editDialogFormData = res.data[0];
					this.editDialogFormData.cfg = JSON.parse(this.editDialogFormData.cfg);
				} 
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {cfg:{d30:0,d90:0,d180:0,d360:0}}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.ActivityCommon.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = {
							token: this.$store.state.user.token,
							form: Object.assign({"tp":4}, this.editDialogFormData)
						}
						params.form.cfg = JSON.stringify(params.form.cfg);
						params.form = JSON.stringify(params.form);
						if(this.editDialogFormData.id > 0){
							await this.$api.Market.ActivityCommon.edit(params);
						}else{
							await this.$api.Market.ActivityCommon.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						});
						this.closeDialog();
						this.getList();
					}
				})
			},
			openlog(row){ //打开兑换日志
				this.$router.push({
					name: "vip-box-log",
					query: {id:row.id},
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
